<template>
  <div>
    <vx-card class="mb-base teacher-insight-card" style="border-radius: 8px;">
      <div class="grid teacher-insight-card">
      <div class="vs-component is-label-placeholder">
        <v-select
          :options="countryOptions"
          :clearable="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="countryFilter"
          class="mb-10 md:mb-0"
        >
          <template #header>
            <label for class="label">Country</label>
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <vs-icon icon="arrow_drop_down"></vs-icon>
            </span>
          </template>
        </v-select>
      </div>

      <div class="vs-component is-label-placeholder">
        <v-select
          :options="stateOptions"
          :clearable="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="stateFilter"
          class="mb-10 md:mb-0"
        >
          <template #header>
            <label for class="label">Select Region</label>
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <vs-icon icon="arrow_drop_down"></vs-icon>
            </span>
          </template>
        </v-select>
      </div>

      <div class="vs-component is-label-placeholder">
        <v-select
          :options="centerOptions"
          :clearable="false"
          multiple
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="centerFilter"
          class="mb-10 md:mb-0"
        >
          <template #header>
            <label for class="label">Select Centre</label>
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <vs-icon icon="arrow_drop_down"></vs-icon>
            </span>
          </template>
        </v-select>
      </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import _ from "lodash";

export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      selectedCountry: "",
      selectedRegion: "",
      centreData: [],
      countryOptions: [],
      stateOptions: [],
      centerOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      centerFilter: "",
      query: {},
      centers: [],
      learningCentersData: []
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    centerId: {
      type: String,
      default: ""
    }
  },
  components: {
    vSelect,
    VuePerfectScrollbar
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };

      if ("all" != obj.value) {
        this.centerOptions = [];
        this.centerFilter = "";
        this.getCentersByCountry();
      } else {
        this.$emit("refreshContent", this.centers);
      }
    },
    stateFilter(obj) {
      if ("all" != obj.value) {
        this.centerFilter = "";
        this.getCentersByCountry();
      }else{
        this.centerFilter = "";
        this.getCentersByCountry();
      }
    },
    centerFilter(obj) {
      if ("all" != obj.value) {
        const centerIds = obj.map(item => {
          return item.value;
        });
        this.query = {
          centerIds: centerIds
        };
        this.$emit("refreshContent", this.query);
        // this.getRooms(this.query.centerIds);
      }
      // this.$emit("refreshDataMain", obj, this.countryFilter.value, this.stateFilter.value);
    },


  },
  methods: {
    ...mapActions("center", [
      "getDirectorAllCountries",
      "getCentreByRegion",
      "getLearningCenterRooms",
      "fetchRoomsByCenterIds",
      "fetchTeacherByRoomIds",
      "getAllLearningCenterListDirector",
      "getCentreByCountry"
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.countryFilter = { label: "All", value: "all" };
      this.stateFilter = { label: "All", value: "all" };
      this.centerFilter = "";
    },
    getCentersByCountry() {
      let data = {
        country:this.countryFilter.value == "all" ? "" : this.countryFilter.value,
        state: this.stateFilter.value == "all" ? "" : this.stateFilter.value
      };
      this.query = [];
      this.getCentreByRegion(data)
        .then(res => {
          let options = [];
          _.forEach(res.data.data, function(element) {
            options.push({
              label: element.name,
              value: element._id,
              center: element.directorId
            });
          });
          this.centerOptions = options;
          const centerIds = this.centerOptions.map(item => {
            return item.value;
          });
          this.query = {
            centerIds: centerIds
          };
          this.$emit("refreshContent", this.query);
          // this.getRooms(this.query.centerIds);
        })
        .catch(err => {});
    },
    // getLearningCenter() {
    //   let data = {
    //     country:this.countryFilter.value == "all" ? "" : this.countryFilter.value,
    //     state: this.stateFilter.value == "all" ? "" : this.stateFilter.value
    //   };
    //   this.getCentreByRegion(data)
    //     .then(res => {
    //       let options = [];
    //       _.forEach(res.data.data, function(element) {
    //         options.push({
    //           label: element.name,
    //           value: element._id,
    //           center: element.directorId
    //         });
    //       });
    //       this.centerOptions = options;
    //       const centerIds = this.centerOptions.map(item => {
    //         return item.value;
    //       });
    //       this.query = {
    //         centerIds: centerIds
    //       };
    //       this.$emit("refreshContent", this.query);
    //     })
    //     .catch(err => {});
    // },


    // async getAllCenterForDirector() {
    //   await this.getAllLearningCenterListDirector(
    //     this.$store.state.AppActiveUser._id
    //   ).then(res => {
    //     this.learningCentersData = res.data.data;
    //     const obj = this.learningCentersData;
    //     const centerIds = obj.map(item => {
    //       return item._id;
    //     });
    //     this.centers = {
    //       centerIds: centerIds
    //     };
    //   });
    // }
  },
  computed: {
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  created() {
    this.getDirectorAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {});
    //this.getAllCenterForDirector();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
.teacher-insight-card{
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 24px;
}

</style>
