<template>
  <div>
    <!--<AddInsightSideBar
      :isSidebarActive="addInsightSidebar"
      @closeSidebar="toggleInsightSidebar"
      :data="insightSidebarData"
      @refreshDataMain="refreshDataMain"
      @refreshContent="refreshContent"
    />-->

    <InsightCenterFilter
      :data="insightSidebarData"
      @refreshDataMain="refreshDataMain"
      @refreshContent="refreshContent"
    />

    <div id="page-user-view">
      <div id="user-data">
        <vx-card class="mb-base">
          <div class="flex items-center mb-5">
            <h2>Rooms</h2>
            <vs-button
              v-if="selectedRooms.length !== totalRoomIds.length"
              class="ml-auto"
              @click="selectAllRooms"
            >
              Select All
            </vs-button>
            <vs-button v-else class="ml-auto" @click="unSelectAllRooms">
              Unselect All
            </vs-button>
          </div>
          <!-- Avatar -->
          <horizontal-scroll>
            <ul class="teacher-list mb-4" v-if="roomData.length">
              <li
                v-for="(part, index) in roomData"
                v-bind:key="index"
                :class="selectedRooms.includes(part._id) ? 'active' : ''"
              >
                <vs-avatar
                  size="large"
                  :src="part.logo ? part.logo : $defaultImage"
                  v-on:click="selectRoom(part, index)"
                />
                <h3>{{ part.name }}</h3>
              </li>
            </ul>
            <span v-else>
            No Room found.
          </span>
          </horizontal-scroll>
        </vx-card>

        <vx-card class="mb-base">
          <div class="flex items-center mb-5">
            <div>
              <h2>Teachers</h2>
            </div>
            <!-- <vs-button @click="addTeacher" class="ml-auto">Add Teacher</vs-button> -->
            <vs-button
              v-if="selectedTeachers.length !== totalTeacherIds.length"
              class="ml-auto"
              @click="selectAllTeachers"
            >
              Select All
            </vs-button>
            <vs-button v-else class="ml-auto" @click="unSelectAllTeachers">
              Unselect All
            </vs-button>
          </div>

          <!-- Avatar -->
          <horizontal-scroll>
            <ul class="teacher-list mb-4" v-if="teacherData.length">
              <li
                v-for="(part, index) in teacherData"
                v-bind:key="index"
                :class="selectedTeachers.includes(part._id) ? 'active' : ''"
              >
                <vs-avatar
                  size="large"
                  :src="part.profileImage ? part.profileImage : $defaultImage"
                  v-on:click="selectTeacher(part, index)"
                />
                <h3>{{ firstNameOnly(part.fullName) }}</h3>
              </li>
            </ul>
            <span v-else>
            No Teachers in this room.
          </span>
          </horizontal-scroll>
        </vx-card>
      </div>
    </div>

    <vx-card class="mb-base" style="border-radius: 8px; padding-bottom: 40px;">
      <div class="flex items-center mb-5">
        <h2>
          Children
        </h2>

        <vs-button
          v-if="selectedChildren.length !== totalChildrenIds.length"
          class="ml-auto"
          @click="selectAllChildren"
        >
          Select All
        </vs-button>
        <vs-button v-else class="ml-auto" @click="unSelectAllChildren">
          Unselect All
        </vs-button>
      </div>

      <horizontal-scroll>
        <ul class="children-list mb-4" v-if="childrenData.length">
        <span v-for="(child, key) in childrenData" v-bind:key="key">
          <li
            :class="
              (selectedChildren.includes(child._id) ? 'active ' : '') +
                'relative inline-block'
            "
            @click="selectChild(child._id)"
          >
            <vs-avatar size="large" :src="child.photo" />
            <span class="badge">
              {{
                "undefined" != typeof child.activities &&
                child.activities.length
                  ? child.activities[0].numberOfActivities
                  : 0
              }}</span
            >
            <p>{{firstNameOnly(child.fullName)}}</p>
          </li>
        </span>
        </ul>
        <span v-else>
        No children in this room.
      </span>
      </horizontal-scroll>
    </vx-card>

    <div class="mt-5 tab-wrapper">
      <vs-tabs class="activity-tab ">
        <vs-tab label="Learning stories">
          <div>
            <LearningStories
              :roomId="roomId"
              :childId="childId"
              :query="query"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></LearningStories>
          </div>
        </vs-tab>
        <vs-tab label="Core skills">
          <div>
            <core-skill-tab
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :query="query"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></core-skill-tab>
          </div>
        </vs-tab>
        <vs-tab label="Fast checks">
          <div>
            <FastCheckTab
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :teacherId="teacherId"
              :query="query"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
              :childrenList="childrenData"
              @loadChild="loadChild"
            >
            </FastCheckTab>
          </div>
        </vs-tab>
        <vs-tab label="Learning Framework">
          <div>
            <ElyfBlock
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :query="query"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></ElyfBlock>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
//import AddInsightSideBar from "./InsightSidebar";
import InsightCenterFilter from "./InsightCenterFilter";

import LearningStories from "./LearningStories";
import CoreSkillTab from "./CoreSkillTab";
import ElyfBlock from "./Eylf";
import FastCheckTab from "./FastCheckTab";
import _ from "lodash";
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
  data() {
    return {
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      activeRoomKey: 0,
      activeTeacherKey: 0,
      roomData: [],
      teacherData: [],
      roomId: null,
      teacherId: null,
      childId: null,
      childrenData: [],
      centerId: null,
      sectionTitle: "",
      teacherName: "",
      insightSidebarData: {},
      countryName: "",
      stateName: "",
      centreName: "",
      fromSidebar: {},
      dataTableParams: {
        country: "all",
        state: "all"
      },
      query: {},
      selectedRooms: [],
      selectedTeachers: [],
      selectedChildren: [],
      totalRoomIds: [],
      totalTeacherIds: [],
      totalChildrenIds: []
    };
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
    //AddInsightSideBar,
    InsightCenterFilter,
    LearningStories,
    CoreSkillTab,
    ElyfBlock,
    FastCheckTab,
    HorizontalScroll
  },
  computed: {
    director() {
      return this.$store.state.AppActiveUser;
    },
    customBreadcrumb() {
      let breadcrumb = [
        { title: this.countryName, url: "#" },
        { title: this.stateName, url: "#" },
        { title: this.centreName, active: true }
      ];
      if (this.centreName && this.countryName && this.selectRoom) {
        this.$emit("customBreadCrumb", breadcrumb);
      }
      return 0;
    }
  },
  methods: {
    ...mapActions("center", [
      "getAllCountries",
      "getCentreByRegion",
      "getDirectorCenterRoomsList",
      "fetchRoomsByCenterIds",
      "fetchTeacherByRoomIds",
      "getAllLearningCenterListDirector"
    ]),
    ...mapActions("room", [
      "getRoomById",
      "getRoomsByDirectorId",
      "getTeachersByRoomId",
      "getChildrenByRoomId",
      "fetchChildrenByMultipleRooms"
    ]),
    firstNameOnly(fullName){
      let name = fullName.split(' ');
      return name[0];
    },
    loadChild(childId){
      this.selectedChildren.push(childId);
    },
    selectAllRooms() {
      this.selectedRooms = JSON.parse(JSON.stringify(this.totalRoomIds));
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);
    },
    unSelectAllRooms() {
      this.selectedRooms = [];
      this.teacherData = [];
      this.childrenData = [];
      this.selectedTeachers = [];
      this.selectedChildren = [];
    },
    selectAllTeachers() {
      this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
    },
    unSelectAllTeachers() {
      this.selectedTeachers = [];
    },
    selectAllChildren() {
      this.selectedChildren = JSON.parse(JSON.stringify(this.totalChildrenIds));
    },
    unSelectAllChildren() {
      this.selectedChildren = [];
    },
    getAllRoomsOfDirector() {
      this.getRoomsByDirectorId(this.$store.state.AppActiveUser._id)
        .then(res => {
          this.totalRoomIds = res.data.data.map(item => {
            return item._id;
          });
          this.roomData = res.data.data;
          this.selectedRooms.push(this.roomData[0]._id);

          if (this.roomData.length > 0) {
            this.roomId = this.roomData[0]._id;
            this.sectionTitle = this.roomData[0].name + " / ";

            this.getAllTeachersOfRoom(this.roomId);
            this.getAllChildrenOfRoom(this.roomId);
          } else {
            this.teacherData = [];
            this.childrenData = [];
          }
        })
        .catch(async err => {
          if (err.response.status === 403) {
            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/director/upgrade-package");
          }
        });
    },
    async getRoomsOfCenter(param) {
      await this.getDirectorCenterRoomsList(param)
        .then(async res => {
          this.roomData = await res.data.data;

          if (this.roomData.length > 0) {
            this.roomId = this.roomData[0]._id;
            this.sectionTitle = this.roomData[0].name + " / ";
            await this.getAllTeachersOfRoom(this.roomId);
            await this.getAllChildrenOfRoom(this.roomId);
          } else {
            this.teacherData = [];
            this.childrenData = [];
          }
          await this.$vs.loading.close();
        })
        .catch(err => {});
    },
    async getRoomsOfMultipleCenter(query) {
      const rooms = await this.fetchRoomsByCenterIds(query);
      this.teacherData = [];
      this.childrenData = [];

      this.roomData = await rooms.data.data;
      if (this.roomData.length > 0) {
        // const roomIds = await this.roomData.map(item => {
        //   return item._id;
        // });
        //await this.getAllTeachersOfMultipleRooms(roomIds);
        //await this.getAllChildrenOfMultipleRoom(roomIds);
        this.selectedRooms = [this.roomData[0]._id];
        await this.getAllTeachersOfMultipleRooms([this.roomData[0]._id]);
        await this.getAllChildrenOfMultipleRoom([this.roomData[0]._id]);
      }

      // this.roomData.forEach((element) => {
      //   this.selectedRooms.push(element._id)
      // });

    },
    addRoom() {
      this.$router.push({
        name: "director-add-room",
        params: { centerId: this.centerId }
      });
    },
    addTeacher() {
      this.$router.push({ name: "director-teachers-add" });
    },
    selectRoom(data, index) {
      this.sectionTitle = data.name + " / ";
      // this.roomId = data._id;
      this.activeRoomKey = index;
      if (this.selectedRooms.includes(data._id)) {
        if (this.selectedRooms.length < 2) {
          alert(
            "You cannot uncheck all rooms. You need to select at least one room."
          );
        } else {
          let index = _.findIndex(this.selectedRooms, function(o) {
            return o == data._id;
          });
          this.selectedRooms.splice(index, 1);
        }
      } else {
        this.selectedRooms.push(data._id);
      }
      // this.getRoomById(data._id)
      //   .then((res) => {
      //     const roomDetails = res.data.data;
      //     this.query = {
      //       centerIds: [roomDetails.learningCenterId]
      //     }
      //   });
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);

    },
    selectTeacher(data, index) {
      if (this.selectedTeachers.includes(data._id)) {
        if (this.selectedTeachers.length < 2) {
          alert(
            "You cannot uncheck all teachers. You need to select at least one room."
          );
        } else {
          let index = _.findIndex(this.selectedTeachers, function(o) {
            return o == data._id;
          });
          //this.selectedTeachers.splice(index, 1);
        }
      } else {
        this.selectedTeachers.push(data._id);
      }


      this.activeTeacherKey = index;
      this.teacherName = data.fullName;
      // this.teacherId = data._id;
    },
    async selectChild(childId) {
      if (this.selectedChildren.includes(childId)) {
        if (this.selectedChildren.length === 1) {
          alert(
            "You cannot uncheck all children. You need to select at least one child."
          );
        } else {
          let index = _.findIndex(this.selectedChildren, function(o) {
            return o === childId;
          });
          this.selectedChildren.splice(index, 1);
        }
      } else {
        this.selectedChildren.push(childId);
      }
    },
    getAllTeachersOfRoom(roomId) {
      this.getTeachersByRoomId(roomId)
        .then(res => {
          this.teacherData = res.data.data;
          this.totalTeacherIds = res.data.data.map(item => {
            return item._id;
          });
          this.teacherId = this.teacherData[0]._id;
          this.selectedTeachers = this.totalTeacherIds;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getAllTeachersOfMultipleRooms(roomIds) {
      const teachers = await this.fetchTeacherByRoomIds(
        JSON.stringify(roomIds)
      );
      this.teacherData = teachers.data.data;
      this.totalTeacherIds = teachers.data.data.map(item => {
        return item._id;
      });
      this.selectedTeachers = [this.teacherData[0]._id];

    },
    getAllChildrenOfRoom(roomId) {
      this.getChildrenByRoomId(roomId)
        .then(res => {
          this.childrenData = res.data.data;
          this.totalChildrenIds = res.data.data.map(item => {
            return item._id;
          });
          this.selectedChildren.push(this.childrenData[0]._id);
          this.$vs.loading.close();
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getAllChildrenOfMultipleRoom(roomIds) {
      const children = await this.fetchChildrenByMultipleRooms(
        JSON.stringify(roomIds)
      );
      this.childrenData = children.data.data;
      this.totalChildrenIds = children.data.data.map(item => {
        return item._id;
      });

      this.selectedChildren = [this.childrenData[0]._id];
    },


    async refreshDataMain(obj = false, country, state, center) {
      this.centreName = center.label;
      this.countryName = country;
      this.stateName = state;
      await this.customBreadcrumb;
      if (center) {
        this.fromSidebar = center;
        let param = {
          search: "",
          id: center.value
        };
        this.centerId = center.value;
        this.$vs.loading();
        await this.getRoomsOfCenter(param);
        this.$vs.loading.close();
      }
    },
    async refreshContent(data) {
      const query = data;
      console.log(query.centerIds,query,"sd");
      if (query.centerIds) {
        this.query = data;
        await this.getRoomsOfMultipleCenter(JSON.stringify(query.centerIds));
      }else{
        console.log(query.centerIds,query,"sd");
      }

    },
    async getAllCenterForDirector() {
      await this.getAllLearningCenterListDirector(this.$store.state.AppActiveUser._id)
        .then((res) => {
          const obj= res.data.data;
          const centerIds = obj.map((item) => {
            return item._id
          });
          this.query = {
            centerIds: centerIds
          }
        })
    }
  },
  async created() {
    await this.customBreadcrumb;
    await this.getAllRoomsOfDirector();
    await this.getAllCenterForDirector();
  }
};
</script>

<style lang="scss" scoped>
/*
///////////*/
#avatar-col {
  width: 10rem;
}

.con-vs-avatar.large {
  width: 71px;
  height: 71px;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

.sidenav {
  height: 100%;
  width: 378px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 10;
  background-color: white;
  overflow: visible;
  padding-top: 130px;
  padding-bottom: 40px;
}

.children-list .con-vs-avatar.large {
  width: 71px;
  height: 71px;
  margin-right: 19px;
  @media (min-width: 768px) {
    margin-right: 35px;
  }
}

.badge {
  background: #2898d5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -3px;
  right: 10px;
  @media (min-width: 768px) {
    right: 25px;
  }
}

.teacher-list h3 {
  color: #289ad5;
  font-size: 14px;
  line-height: 18px;
}
ul.children-list .active .con-vs-avatar {
  border: 4px solid #289ad5;
}
</style>
